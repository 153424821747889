<template>
  <div>
    <div class="footer-bar-wrap">
    <div class="footer-bar">
      <div class="sctp-container group-list">
        <div class="group">
          <div class="title">关于我们</div>
          <div>
            <router-link to="/help-center">帮助中心</router-link>
          </div>
          <div>软件项目管理</div>
          <div>
            <a href="https://www.mlpmis.cn" target="_blank">默蓝项目管理信息系统</a>
          </div>
        </div>
        <div class="split-line"></div>
        <div class="group">
          <div class="title">商务合作</div>
          <div>商务经理：{{$store.state.setting.contactUsername}}</div>
          <!---
          <div>联系电话：{{$store.state.setting.contactUsTel}}</div>
          -->
          <div>EMail邮箱：{{$store.state.setting.email}}</div>
        </div>
        <div class="split-line"></div>
        <div class="group">
          <div class="title">联系我们</div>
          <div>公司地址：{{$store.state.setting.companyAddress}}</div>
          <!--
          <div>技术咨询: {{$store.state.setting.technologyConsultingPhone}}</div>
          -->
          <div>QQ号码：{{$store.state.setting.contactUsQQ}}</div>
          <!--
          <div>媒体合作：{{$store.state.setting.mediaCooperation}}</div>
          -->
          <div>电子邮箱：{{$store.state.setting.email}}</div>
          <div>&emsp;&emsp;邮编：{{$store.state.setting.postcode}}</div>
        </div>
        <div class="group" style="float: right; text-align: right;">
          <div class="title">服务支持：{{$store.state.setting.contactUsTel}}</div>
          <div class="title">{{$store.state.setting.contactUsTime}}</div>
          <el-image style="height: 105px; wight:105px" :src="$store.state.setting.businessQrCode"></el-image>
        </div>
      </div>
    </div>
    <div class="sctp-pad-tb15 bottom-line">
      <div class="sctp-container" style="text-align: center;display: flex; justify-content: space-between;">
        <div style="display: inline-block">
          <span>默蓝网技术研发团队&nbsp;版权所有&nbsp;@2021&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">
            浙ICP备2021037564号-2
          </a>
          </span>
          <span style="margin-left: 20px;">官方网址：{{$store.state.setting.officialSite}}</span>
        </div>
        <div style="display: inline-block">
          <span class="sctp-mar-r15">
            <router-link to="/protocol/1">法律声明</router-link>
          </span>
          <span style="margin-left: 20px;">
            <router-link to="/protocol/2">隐私协议</router-link>
          </span>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
  export default {
    name: 'GlobalFooter',
    props: [],
    data() {
      return {
        links: null
      }
    },
    methods: {},
    beforeMount() {
    }
  }
</script>

<style scoped>
  /*@formatter:off*/
  .footer-bar-wrap {
    color: #929292;
    font-size: 12px;
    width: 100%;
    background-color: #303643;
    position: relative;
  }

  .footer-bar {
    padding: 10px 0 30px 0;
  }

  .group-list {
  }

  a, a:link, a:visited {
    color: #929292 !important;
  }

  .group {
    display: inline-block;
    vertical-align: top;
    line-height: 26px;
    text-align: left;
  }

  .group .title {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .group ::v-deep img {
    height: 90px;
    width: 95px;
    object-fit: cover;
  }

  .split-line {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0 50px;
  }

  .split-line:before {
    content: '';
    display: block;
    height: 80px;
    border-right: 1px solid white;
  }

  .bottom-line span + span:not(:last-child) {
    margin-left: 70px;
  }

  .bottom-line {
    border-top: 1px solid #5b616f;
  }



  /*@formatter:on*/
</style>
